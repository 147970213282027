<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="500" scrollable>
      <v-card>
        <v-card-title class="headline">{{ labels.title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-container fluid>
                  <v-autocomplete
                    v-model="selected"
                    :disabled="isUpdating"
                    :items="items"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    :label="title"
                    :item-text="keyField"
                    item-value="id"
                    multiple
                    return-object
                    :loading="loading"
                    clearable
                    deletable-chips
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggle">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              selected.length > 0 ? 'indigo darken-4' : ''
                            "
                            >{{ icon }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            labels.select_all
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="
              $emit('dialog-response', false);
              selected = [];
            "
            >{{ labels.button_cancel }}</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="addRecords"
            :loading="adding"
            >{{ labels.button_add }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AppSubgridSelect",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    relatedEntity: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      items: [],
      selected: [],
      adding: false,
      loading: false,
      isUpdating: false,
      autoUpdate: true,
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    show(val) {
      if (val) {
        this.loading = true;
        this.$store
          .dispatch("subgridNN/getUnusedLinkList", {
            entityName: this.entity,
            id: this.id,
            relatedEntity: this.relatedEntity,
          })
          .then((response) => {
            this.items = response;
            this.loading = false;
          });
      }
    },
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("subgrid_nn").subgrid_select;
      },
    },
    selectAll() {
      return this.selected.length === this.items.length;
    },
    selectSome() {
      return this.selected.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "mdi-close-box";
      if (this.selectSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.selected = [];
        } else {
          this.selected = this.items.slice();
        }
      });
    },
    addRecords() {
      this.adding = true;
      this.$store
        .dispatch("subgridNN/linkList", {
          entityName: this.entity,
          id: this.id,
          relatedEntity: this.relatedEntity,
          data: this.selected,
        })
        .then(() => {
          this.$store.commit("snackbar/show", {
            text: this.labels.events.success.select,
            type: "success",
          });
          this.adding = false;
          this.selected = [];
          this.$emit("dialog-response", true);
        })
        .catch(() => {
          this.$store.commit("snackbar/show", {
            text: this.labels.events.error.select,
            type: "error",
          });
          this.deleting = false;
          this.$emit("dialog-response", false);
        });
    },
  },
};
</script>