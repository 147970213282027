<template>
  <div>
    <app-delete-dialog
      :show="showDeleteDialog"
      v-on:dialog-response="deleteRecord"
    >
      <template v-slot:message>
        <div class="mb-2">{{ labels.delete_message }}</div>
      </template>
    </app-delete-dialog>
    <app-page :breadcrumbs="breadcrumbs" :title="title">
      <template v-slot:toolbar>
        <app-toolbar :entity="entity" :progress="progress">
          <template v-slot:buttons="{ can, lb }">
            <div v-if="!removeButtons">
              <v-btn
                class="mr-1"
                @click="newRecord"
                v-if="id !== 'create' && can.create"
              >
                <v-icon left>mdi-plus</v-icon> {{ lb.new }}
              </v-btn>
              <v-btn
                class="mr-1"
                @click="updateRecord"
                v-if="id !== 'create' && can.edit"
                :loading="updating"
              >
                <v-icon left>mdi-content-save-outline</v-icon>
                {{ lb.update }}
              </v-btn>
              <v-btn
                class="mr-1"
                @click="createRecord"
                v-if="id == 'create' && can.create"
                :loading="creating"
              >
                <v-icon left>mdi-content-save-outline</v-icon>
                {{ lb.save }}
              </v-btn>
              <v-btn
                class="mr-1"
                @click="showDeleteDialog = true"
                v-if="id !== 'create' && can.delete"
                :loading="deleting"
              >
                <v-icon left>mdi-delete-outline</v-icon>
                {{ lb.delete }}
              </v-btn>
            </div>
            <slot name="custom-buttons" v-bind:lb="lb" v-bind:can="can"></slot>
          </template>
        </app-toolbar>
      </template>
      <template v-slot:content>
        <v-container>
          <v-row>
            <v-col>
              <slot name="content"></slot>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:subgrid v-if="id != 'create'">
        <slot name="subgrid"></slot>
      </template>
    </app-page>
  </div>
</template>
<script>
const Toolbar = () =>
  import(/* webpackChunkName: "profile" */ "@/components/Toolbar");
const DeleteDialog = () =>
  import(/* webpackChunkName: "common" */ "@/components/DeleteDialog");
export default {
  name: "FormToolbar",
  components: {
    "app-toolbar": Toolbar,
    "app-delete-dialog": DeleteDialog,
  },
  props: {
    id: {
      type: String,
    },
    entity: {
      type: String,
    },
    breadcrumbs: {
      type: Array,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    removeButtons: {
      type: Boolean,
    },
    refName: {
      type: String,
    },
  },
  data: () => ({
    updating: false,
    creating: false,
    loadingRecord: false,
    deleting: false,
    showDeleteDialog: false,
  }),
  beforeMount() {
    this.id && this.id != "create" ? this.getRecord() : "";
  },
  beforeDestroy() {
    this.$store.commit("formPage/SET_RECORD", {});
  },
  methods: {
    newRecord() {
      if (this.id !== "create") {
        this.$router.push({ path: `/${this.entity}/create` });
      }
      this.$store.commit("formPage/SET_RECORD", {});
      this.formRef.reset();
    },
    getRecord() {
      this.loadingRecord = true;
      this.$store
        .dispatch("formPage/getRecord", {
          entity: this.entity,
          id: this.id,
        })
        .finally(() => {
          this.loadingRecord = false;
        });
    },
    createRecord() {
      if (this.validateForm()) {
        this.creating = true;
        this.$store
          .dispatch("formPage/createRecord", { entity: this.entity })
          .then((id) => {
            this.$router.push({ path: `/${this.entity}/${id}` });
            this.$store.commit("snackbar/show", {
              text: this.labels.events.success.create,
              type: "success",
            });
          })
          .catch(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.error.create,
              type: "error",
            });
          })
          .finally(() => {
            this.creating = false;
          });
      }
    },
    updateRecord() {
      if (this.validateForm()) {
        this.updating = true;
        this.$store
          .dispatch("formPage/updateRecord", { entity: this.entity })
          .then(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.success.update,
              type: "success",
            });
          })
          .catch(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.error.update,
              type: "error",
            });
          })
          .finally(() => {
            this.updating = false;
          });
      }
    },
    deleteRecord(userResponse) {
      this.showDeleteDialog = false;
      if (userResponse) {
        this.deleting = true;
        this.$store
          .dispatch("formPage/deleteRecord", {
            entity: this.entity,
            id: this.id,
          })
          .then(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.success.delete,
              type: "success",
            });
            this.$router.push({ path: `/${this.entity}` });
          })
          .catch(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.error.delete,
              type: "error",
            });
          })
          .finally(() => {
            this.deleting = false;
          });
      }
    },
    validateForm() {
      if (!this.formRef.validate()) {
        this.$store.commit("snackbar/show", {
          text: this.labels.form_validation,
          type: "red lighten-1",
        });
      }
      return this.formRef.validate();
    },
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("form_page");
      },
    },
    progress: {
      get() {
        return (
          this.loading ||
          this.loadingRecord ||
          this.updating ||
          this.creating ||
          this.deleting
        );
      },
    },
    formRef: {
      get() {
        return this.$parent.$refs[this.refName];
      },
    },
  },
};
</script>