<template>
  <div>
    <div>
      <div class="display-1">{{ title }}</div>
      <v-breadcrumbs
        :items="breadcrumbs"
        divider="/"
        class="pl-0 pt-0"
      ></v-breadcrumbs>
      <v-spacer></v-spacer>
    </div>
    <slot name="toolbar"></slot>
    <v-card>
      <slot name="content"></slot>
    </v-card>
    <slot name="subgrid"></slot>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  props: {
    breadcrumbs: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
    },
  },
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>
