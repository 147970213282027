<template>
  <v-snackbar
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiline === true"
    top
    :color="snackbar.color"
    v-model="snackbar.visible"
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn text dark @click.native="closeSnackbar" v-bind="attrs">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "AppSnackbar",
  computed: {
    snackbar() {
      return this.$store.state.snackbar.props;
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("snackbar/close");
    },
  },
};
</script>