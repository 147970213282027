<template>
  <v-dialog v-model="show" persistent max-width="500" scrollable>
    <v-card>
      <v-card-title class="headline">{{ labels.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <slot name="message"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="gray darken-1"
          text
          v-on:click="$emit('dialog-response', false)"
          >{{ labels.button_cancel }}</v-btn
        >
        <v-btn
          color="red darken-1"
          text
          v-on:click="$emit('dialog-response', true)"
          >{{ labels.button_delete }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DeleteDialog",
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("delete_dialog");
      },
    },
  },
};
</script>