<template>
  <div>
    <app-delete-dialog
      :show="showDeleteDialog"
      v-on:dialog-response="deleteItems"
    >
      <template v-slot:message>
        <div class="mb-2">{{ labels.delete_message }}</div>
        <div
          class="font-weight-black mx-0"
          v-for="item in selected"
          v-bind:key="item.id"
        >
          - {{ item[headers[0].value] }}
        </div>
      </template>
    </app-delete-dialog>
    <app-page :breadcrumbs="breadcrumbs" :title="title">
      <template v-slot:toolbar>
        <app-toolbar :entity="entity" :progress="progress">
          <template v-slot:buttons="{ can, lb }">
            <v-btn class="mr-1" :to="entity + '/create'" v-if="can.create">
              <v-icon left>mdi-plus</v-icon> {{ lb.new }}
            </v-btn>
            <v-btn
              class="mr-1"
              v-if="selected.length == 1 && can.edit"
              :to="entity + '/' + selected[0].id"
            >
              <v-icon left>mdi-pencil-outline</v-icon> {{ lb.edit }}
            </v-btn>
            <v-badge
              bordered
              icon="mdi-lock"
              color="grey lighten-1"
              overlap
              v-if="selected.length && can.delete"
              :content="selected.length"
            >
              <v-btn class="mr-1" @click="showDeleteDialog = true">
                <v-icon left>mdi-delete-outline</v-icon>
                {{ lb.delete }}
              </v-btn>
            </v-badge>
            <slot name="custom" v-bind:lb="lb" v-bind:can="can"></slot>
          </template>
        </app-toolbar>
      </template>
      <template v-slot:content>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="labels.search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          show-select
          item-key="id"
          :loading="loading"
          v-model="selected"
          mobile-breakpoint="300"
          @click:row="openDetails"
          class="row-pointer"
        >
        </v-data-table>
      </template>
    </app-page>
  </div>
</template>

<script>
const Toolbar = () =>
  import(/* webpackChunkName: "common" */ "@/components/Toolbar");
const DeleteDialog = () =>
  import(/* webpackChunkName: "common" */ "@/components/DeleteDialog");
export default {
  name: "DataTable",
  components: {
    "app-toolbar": Toolbar,
    "app-delete-dialog": DeleteDialog,
  },
  props: {
    breadcrumbs: {
      type: Array,
    },
    columns: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    entity: {
      type: String,
      required: true,
    },
    customBody: {
      type: Boolean,
    },
  },
  data: () => ({
    search: "",
    selected: [],
    items: [],
    loading: false,
    deleting: false,
    showDeleteDialog: false,
  }),
  beforeMount() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      this.$store
        .dispatch("dataTable/getList", {
          entity: this.entity,
          fields: this.fields,
        })
        .then((data) => {
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDetails(item) {
      this.$router.push(`${this.entity}/${item.id}`);
    },
    deleteItems(userResponse) {
      this.showDeleteDialog = false;
      if (userResponse) {
        this.deleting = true;
        this.$store
          .dispatch("dataTable/deleteItems", {
            entity: this.entity,
            items: this.selected.map((record) => {
              return { id: record.id };
            }),
          })
          .then(() => {
            this.removeSelected();
            this.$store.commit("snackbar/show", {
              text: this.labels.events.success.delete,
              type: "success",
            });
          })
          .catch(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.error.delete,
              type: "error",
            });
          })
          .finally(() => {
            this.deleting = false;
          });
      }
    },
    removeSelected() {
      this.selected.forEach(function (item) {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
      }, this);
      this.selected = [];
    },
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("data_table");
      },
    },
    headers: {
      get() {
        let head = [];
        let label = this.$store.getters["labels"](this.entity);
        this.columns.forEach((column) => {
          head.push({
            text: label[column.name],
            value: column.name,
          });
        });

        return head;
      },
    },
    progress: {
      get() {
        return this.deleting;
      },
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>