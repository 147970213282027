<template>
  <div class="mt-3">
    <v-card>
      <app-delete-dialog
        :show="showDeleteDialog"
        v-on:dialog-response="bulkDelete"
      >
        <template v-slot:message>
          <div class="mb-2">{{ labels.delete_message }}</div>
          <div
            class="font-weight-black mx-0"
            v-for="item in selected"
            v-bind:key="item.id"
          >
            - {{ item[headers[0].value] }}
          </div>
        </template>
      </app-delete-dialog>

      <app-subgrid-select
        :show="showSubGridSelect"
        :title="title"
        v-on:dialog-response="getRecords"
        :entity="entity"
        :relatedEntity="relatedEntity"
        :keyField="headers[0].value"
        :id="id"
      />

      <app-toolbar :entity="relatedEntity" :progress="progress">
        <template v-slot:buttons="{ can, lb }">
          <v-btn
            class="mx-1"
            v-if="can.create"
            @click="showSubGridSelect = true"
          >
            <v-icon left>mdi-plus</v-icon> {{ lb.add }}
          </v-btn>
          <v-badge
            bordered
            icon="mdi-lock"
            color="grey lighten-1"
            overlap
            v-if="can.delete && selected.length"
            :content="selected.length"
          >
            <v-btn
              class="mx-1"
              light
              v-if="can.delete && selected.length"
              @click="showDeleteDialog = true"
              :loading="deleting"
            >
              <v-icon left>mdi-delete</v-icon> {{ lb.remove }}
            </v-btn>
          </v-badge>
          <slot name="custom" v-bind:lb="lb" v-bind:can="can"></slot>
        </template>
      </app-toolbar>

      <v-card-title class="py-0">
        <v-container fluid class="pa-0">
          <v-row align="baseline">
            <v-col cols="4">
              {{ title }}
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="labels.search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        disable-sort
        mobile-breakpoint="300"
        :loading="loading"
      >
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
const Toolbar = () =>
  import(/* webpackChunkName: "common" */ "@/components/Toolbar");
const DeleteDialog = () =>
  import(/* webpackChunkName: "common" */ "@/components/DeleteDialog");

const SubgridSelect = () =>
  import(/* webpackChunkName: "common" */ "@/components/SubgridSelect");
export default {
  name: "SubgridNN",
  components: {
    "app-toolbar": Toolbar,
    "app-delete-dialog": DeleteDialog,
    "app-subgrid-select": SubgridSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    relatedEntity: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      selected: [],
      loading: true,
      deleting: false,
      adding: false,
      showDeleteDialog: false,
      showSubGridSelect: false,
      items: [],
    };
  },
  beforeMount() {
    this.getRecords(true);
  },
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("subgrid_nn");
      },
    },
    headers: {
      get() {
        let head = [];
        let label = this.$store.getters["labels"](this.relatedEntity);
        this.columns.forEach((column) => {
          head.push({
            text: label[column.name],
            value: column.name,
          });
        });

        return head;
      },
    },
    title: {
      get() {
        return this.$store.getters["labels"](this.relatedEntity).title;
      },
    },
    progress: {
      get() {
        return this.deleting || this.adding;
      },
    },
  },
  watch: {
    id(val) {
      if (val != "create") this.getRecords(true);
    },
  },
  methods: {
    bulkDelete(dialogResponse) {
      this.showDeleteDialog = false;
      if (dialogResponse) {
        this.deleting = true;
        this.$store
          .dispatch("subgridNN/removeLinkList", {
            entityName: this.entity,
            id: this.id,
            relatedEntity: this.relatedEntity,
            data: this.selected,
          })
          .then(() => {
            this.selected = [];
            this.$store.commit("snackbar/show", {
              text: this.labels.events.success.delete,
              type: "success",
            });
          })
          .catch(() => {
            this.$store.commit("snackbar/show", {
              text: this.labels.events.error.delete,
              type: "error",
            });
          })
          .finally(() => {
            this.deleting = false;
            this.getRecords(true);
          });
      }
    },
    getRecords(response) {
      this.showSubGridSelect = false;
      if (response && this.recordId != "create") {
        this.loading = true;
        this.$store
          .dispatch("subgridNN/getLinkList", {
            entityName: this.entity,
            id: this.id,
            relatedEntity: this.relatedEntity,
          })
          .then((response) => {
            this.items = response;
            this.loading = false;
          });
      }
    },
  },
};
</script>
