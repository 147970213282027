<template>
  <v-overlay :absolute="absolute" :value="active" :opacity="opacity">
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      :width="7"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "Spinner",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    opacity: {
      type: String,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
};
</script>